import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import QpCardUtil,{IQpCardDataObj} from './qpCardUtil';
import QptzdCard from "@/views/project/order/fy/zl/qptzd/QptzdCard.vue";
import ChildOrderList from "@/views/project/order/order/xdgl/splitOrder/childOrder/ChildOrderList.vue";
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";
export default defineComponent({
    name:'QpCard',
    title:'启票信息',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IQpCardDataObj=reactive<IQpCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    qpDetail:{},
                },
                details:['qpDetail'],
                modelPath: utils.OrderProviderApi.buildUrl('/qp')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                qpdwmc: [utils.UtilPub.commonValidRule('请输入启票单位名称')],
                scdwmc: [utils.UtilPub.commonValidRule('请输入收车单位名称')],
            },
            otherParams:{
                childOrderId:'',//拆单明细id
                customerData:[],//客户下拉数据
                type:''//订单是否进行了拆单，拆单了是1，为拆单是0，拆单了，上面的id是拆单明细id，没有拆单，上面的id是订单id
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new QpCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        gridTitle:'启票明细',
                        readOnly:dataObj.disabled,
                        canPage: false,
                        canAdd: false,
                        toolBar: [{
                            id: "chooseOrder",
                            title: "选择订单",
                            type: "primary",
                            icon: "iconfont icon-chaxun",
                            clickEvent: 'chooseOrder',
                            param:{}
                        }],
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/qp/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('customerId'==selectId){
                dataObj.refMap.get('qpDetail').clearDetailData();
            }
        }
        //选择订单按钮事件
        const chooseOrder=async ()=>{
            if(dataObj.form.customerId){
                await utils.UtilPub.openDialog({
                    proxy:proxy,modelComp:ChildOrderList,ownerComp:proxy,customerId:dataObj.form.customerId,
                    from:'QpCard',overflow:'hidden',title: '选择订单',showFooterBtn:true,fullscreen:true})
            }else{
                proxy.$message('请选择买家');
            }
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            dataObj.utilInst.doSureChoose(selectRows)
            return true;
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if(res.data.flag!=0){
                dataObj.disabled=true;
                dataObj.refMap.get('qpDetail').setReadOnly(true);
            }
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{
            //todo:如果明细中有cascader，而且每行的cascader数据可能不同，那么可以在这里再次请求后台，重新根据条件构造该行cascader数据
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            if('qpDetail'==options.detailType){}
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            let qpDetailData=dataObj.refMap.get('qpDetail').getDetailData();
            if(qpDetailData.length==0){
                proxy.$message({type:'warning',message:'启票明细不能为空'});
                return false;
            }
            // let customerIds:any=new Set([]);//启票明细中包含的客户
            let result=qpDetailData.findIndex((row:any,index:number)=>{
                if(!row.F_XZPZ || (!row.F_NUM || row.F_NUM==0)){
                    utils.Tools.warning({message:'第'+(index+1)+'行数据有误或不全'});
                    return true;
                }
                // customerIds.add(row.F_CUSTOMER_ID);
                return false;
            })
            if(result!=-1)return false;
            // if(customerIds.size>1){
            //     proxy.$message({type:'warning',message:'不能同时启票多个客户的订单'});
            //     return false;
            // }else{
            //     return true;
            // }
        }
        //计算某行的总金额
        const rowAmount=computed(()=>{
            return (row:any) => {
                if(row.F_NUM && row.F_PRICE){
                    let amount=row.F_NUM*row.F_PRICE;
                    row.F_AMOUNT=amount;
                    return amount;
                }
                row.F_AMOUNT=0;
                return 0;
            };
        })
        //预览启票通知单
        const preViewQptzdHandler=async ()=>{
            await utils.UtilPub.openDialog({id:dataObj.form.id,proxy:proxy,addOrEdit:'/load',modelComp:QptzdCard,ownerComp:proxy,
                cardFrom:'QpCard',overflow:'hidden',title: '启票通知单',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:FormalOrderDetail,ownerComp:proxy,
                orderId:row.F_ORDER_ID,childOrderCode:row.F_SPLIT_ORDER_CODE,
                cardFrom:'QpCard',overflow:'hidden',title: '订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //提交
        const submitHandler=async ()=>{
            await dataObj.utilInst.submit();
        }
        //确认发货通知
        const qrfhtzHandler=async ()=>{
            await dataObj.utilInst.doQrfhtz();
        }
        return{
            ...toRefs(dataObj),proxy,chooseOrder,sureHandler,comboSelect,cardDetailParam,selectOnChange,beganEdit,beforeEndEdit,beforeOpen,setParam,beforeSaveHandler
         ,rowAmount,preViewQptzdHandler,orderDetail,submitHandler,qrfhtzHandler
        }
    }
});